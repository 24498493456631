<template>
  <div class="productList">
    <div class="productDetails">
      <img src="../../images/d8.png"/>
      <div class="productTxt">
        <p>中海达 D8PRO</p>
        <div class="activity">
          <div>全新</div>
          <div>免押租赁</div>
        </div>
        <div class="price">
          <div>
            <span>￥</span>
            <span>19.0</span>
            <span>/天</span>
          </div>
          <div>
            七天起租
          </div>
        </div>
      </div>
    </div>
    <div class="productDetails">
      <img src="../../images/d8.png"/>
      <div class="productTxt">
        <p>中海达 D8PRO</p>
        <div class="activity">
          <div>全新</div>
          <div>免押租赁</div>
        </div>
        <div class="price">
          <div>
            <span>￥</span>
            <span>19.0</span>
            <span>/天</span>
          </div>
          <div>
            七天起租
          </div>
        </div>
      </div>
    </div>
    <div class="productDetails">
      <img src="../../images/d8.png"/>
      <div class="productTxt">
        <p>中海达 D8PRO</p>
        <div class="activity">
          <div>全新</div>
          <div>免押租赁</div>
        </div>
        <div class="price">
          <div>
            <span>￥</span>
            <span>19.0</span>
            <span>/天</span>
          </div>
          <div>
            七天起租
          </div>
        </div>
      </div>
    </div>
    <div class="productDetails">
      <img src="../../images/d8.png"/>
      <div class="productTxt">
        <p>中海达 D8PRO</p>
        <div class="activity">
          <div>全新</div>
          <div>免押租赁</div>
        </div>
        <div class="price">
          <div>
            <span>￥</span>
            <span>19.0</span>
            <span>/天</span>
          </div>
          <div>
            七天起租
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productList",
  data(){
    return {

    }
  },
  props:{
    hotProducts : Array
  },
  created() {

  },
  mounted() {
    console.log(this.hotProducts)
  },
  methods : {

  }
}
</script>

<style scoped>
.productList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.productDetails {
  width: 4.55rem;
  height: 6.83rem;
  background: #FFFFFF;
  margin-bottom:0.28rem ;
  border-radius: 0.28rem;
}

.productTxt {
  padding: 0rem 0.32rem 0rem 0.32rem;
}

.productDetails img {
  width: 100%;
  border-top-left-radius: 0.28rem;
  border-top-right-radius: 0.28rem;
}

.productDetails p {
  font-size: 0.39rem;
  font-family: xiaocheng;
  font-weight: 400;
  color: #333333;
}

.activity {
  display: flex;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.activity div {
  height: 0.47rem;
  border: 1px solid #FFE1BE;
  border-radius: 0.1rem;
  font-size: 0.33rem;
  font-family: xiaocheng;
  font-weight: 400;
  color: #FF9966;
  padding: 0.08rem 0.11rem;

}

.activity div:nth-child(1) {
  margin-right: 0.14rem;
}
.price{
  display: flex;
  justify-content: space-between;
}
.price div:nth-child(1) span:nth-child(1){
  font-size: 0.33rem;
  font-family: xiaocheng;
  font-weight: 400;
  color: #FB884F;
}

.price div:nth-child(1) span:nth-child(2){
  font-size: 0.47rem;
  font-family: xiaocheng;
  font-weight: 600;
  color: #FB884F;
}

.price div:nth-child(1) span:nth-child(3){
  font-size: 0.33rem;
  font-family: xiaocheng;
  font-weight: 400;
  color: #FB884F;
}

.price div:nth-child(2) {
  font-size: 0.33rem;
  font-family: xiaocheng;
  font-weight: 400;
  color: #999999;
  align-self: flex-end;
}
</style>
