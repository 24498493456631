<template>
  <div class="home">
    <div class="headerBanner">
      <!--   轮播-->
      <div class="indexswipe">
        <van-swipe
            class="banner">
          <van-swipe-item>
            <img src="../images/banner.png"/>
          </van-swipe-item>
          <van-swipe-item>
            <img src="../images/banner.png"/>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!--   服务-->
      <div class="service">
        <div class="serviceCol">
          <img src="../images/leasing.png">
          <span>免押租赁</span>
        </div>
        <div class="serviceCol">
          <img src="../images/facility.png">
          <span>官网正品设备</span>
        </div>
        <div class="serviceCol">
          <img src="../images/service.png">
          <span>7*24h在线客服</span>
        </div>
      </div>
      <!-- 产品-->
      <div class="product">
        <div v-for="v in productTitle" :key="v.icon" @click="toCategory(v.id)">
          <img :src="v.icon"/>
          <div class="title">
            {{v.name}}
          </div>
        </div>



        <div @click="toMore()">
          <img :src="require('../images/classify.png')"/>
          <div class="title">
            更多
          </div>
        </div>
      </div>
    </div>
    <!-- 组五大攻略s-->
<!--    <div class="RentThings" @click="gorentImgs">-->
<!--      <img src="../images/index_rentN.png" alt="">-->
<!--    </div>-->
<!--    人气榜单-->
    <div class="popularityList">
      <div class="popularityListTitle">
        <div>人气榜单</div>
        <div>热租榜单</div>
      </div>
      <div class="popularityListContent">
        <div class="popularityListContentList" v-for="(item,index) in hostList" :key="item.id" @click="toProductDetails(item.id)">
          <div class="popularityListContentImg">
            <img :src="item.pic">
            <img src="../images/first.png" class="ranking" v-if="index==0">
            <img src="../images/second.png" class="ranking"  v-if="index==1">
            <img src="../images/thirdly.png" class="ranking"  v-if="index==2">
            <img src="../images/fourthly.png" class="ranking"  v-if="index==3">
          </div>
          <h2>{{ item.name }}</h2>
          <p><span>￥</span>{{ item.showPrice }}<span>/天</span></p>
        </div>
      </div>
    </div>
    <div class="cooperativeBrand">
      <h2>合作品牌</h2>
      <div class="cooperativeBrandImg">
        <img src='../images/zhonghaida.png'>
        <img src='../images/nanfang .png'>
        <img src='../images/huaceImg.png'>
        <img src='../images/ufo.png'>
        <img src='../images/tuopukang.png'>
        <img src='../images/tianbao.png'>
      </div>
    </div>
    <!-- 热门设备-->
    <div class="hotEquipment">
      <h3>
        热门设备
      </h3>
      <div class="productList">
        <div class="productDetails" v-for="v in hotProducts" :key="v.id" @click="toProductDetails(v.id)">
          <img :src="v.pic" />
          <div class="productTxt">
            <p>{{ v.name }}</p>
            <div class="activity">
              <div>全新</div>
              <div>免押租赁</div>
            </div>
            <div class="price">
              <div>
                <span>￥</span>
                <span>{{ v.showPrice }}</span>
                <span>/</span>
                <span>天</span>
              </div>
              <div v-if="v.name!='测绘玩家A10'">
                七天起租
              </div>
              <div v-else>
                361天起租
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productList from "./components/productList";
import leaseApi from "../../utils/leaseApi";
import TEMPURL from "../../utils/tempurl";

export default {
  name: "home",
  components: {
    productList   //注册组件
  },
  data() {
    return {
      hotProducts : [],
      productTitle: [],
      hostList:[]
    };
  },
  created() {
    this.fetchData()
  },
  methods : {
    async fetchData(){
      const { data } = await leaseApi.getDashBoard();
      data.classifies.forEach(c => {
        c.icon = TEMPURL + c.icon
      })
      data.commodityDTOS.forEach(c => {
        c.pic = TEMPURL + c.pic
      })
      this.productTitle = data.classifies
      this.hotProducts = data.commodityDTOS.reverse();
      this.hostList=data.commodityDTOS.slice(0,8)
    },
    toMore(){
      this.$router.push('/classify')
    },
    toCategory(categoryId){
      this.$router.push({
        path : '/classify',
        query : {
          categoryId : categoryId
        }
      })
    },
    toProductDetails(productId){
      this.$router.push({
        path : '/product',
        query : {
          productId : productId
        }
      })
    },
    gorentImgs(){
      this.$router.push({path:'/lease/rentThings'})
    }
  }
}
</script>

<style scoped>
.home {
  padding: 0.31rem 0.32rem 1.5rem 0.32rem;
  background: linear-gradient(rgba(250, 249, 248, 1), rgba(255, 255, 255, 1) 100%);
  /*height: 100%;*/
}

.headerBanner {
  margin-bottom: 0.5rem;
}
.indexswipe{
  overflow: hidden;
  transform: translateY(0);
  height: 4.7rem;
  border-radius: 0.11rem;
}


.banner img {
  width: 100%;
}

.service img {
  width: 0.42rem;
}

.service span {
  font-size: 0.29rem;
  /*font-family: 'xiaocheng';*/
  font-weight: 400;
  color: #666666;
  margin-left: 0.04rem;
}
.serviceCol:nth-child(1) span{
  margin-left: 0rem;
}
.service {
  display: flex;
  padding: 0rem 0.39rem;
  justify-content: space-between;
  margin-top: 0.36rem;
}

.serviceCol {
  display: flex;
  align-items: center;
}

.serviceCol img {
}

.product {
  margin-top: 0.56rem;
  /*padding: 0rem 0.64rem;*/
  display: flex;
  text-align: center;
  justify-content: space-around;
}

.product img {
  width: 1.28rem;
  height: 1.28rem;
}

.product .title {
  font-size: 0.36rem;
  /*font-family: 'xiaocheng';*/
  font-weight: 400;
  color: #333333;
}
.hotEquipment{
  padding-bottom: 1rem;
}
.hotEquipment h3 {
  font-size: 0.45rem;
  /*font-family: 'xiaocheng';*/
  padding-left: 0.31rem;
  font-weight: 550;
  color: #333333;
  margin-bottom: 0.44rem;
}

.productList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.productDetails {
  width: 4.55rem;
  background: #FFFFFF;
  margin-bottom:0.28rem ;
  border-radius: 0.21rem;
  padding-bottom:0.444rem;
}

.productTxt {
  padding: 0rem 0.32rem 0rem 0.32rem;
}

.productDetails img {
  width: 100%;
  height: 4.28rem;
  border-top-left-radius: 0.28rem;
  border-top-right-radius: 0.28rem;
}

.productDetails p {
  font-size: 0.39rem;
  /*font-family: 'xiaocheng';*/

  font-weight: 400;
  color: #333333;
}

.activity {
  display: flex;
  margin-bottom: 0.172rem;
  margin-top: 0.233rem;
}

.activity div {
  /*height: 0.47rem;*/
  border: 0.028rem solid #FFE1BE;
  border-radius: 0.1rem;
  font-size: 0.33rem;
  /*font-family: 'xiaocheng';*/

  font-weight: 400;
  color: #FF9966;
  padding: 0rem 0.155rem;
}

.activity div:nth-child(1) {
  margin-right: 0.14rem;
}
/*.activity div:nth-child(2) {*/
/*  border-right: 1px solid #FFE1BE;*/
/*}*/
.price{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price div:nth-child(1){
  display: flex;
  align-items: center;
}
.price div:nth-child(1) span:nth-child(1){
  font-size: 0.33rem;
  color: #FB884F;
}

.price div:nth-child(1) span:nth-child(2){
  font-size: 0.55rem;
  /*font-family: 'xiaocheng';*/

  font-weight: 600;
  color: #FB884F;
  margin-left: -0.08rem;
}

.price div:nth-child(1) span:nth-child(3){
  font-size: 0.33rem;
  /*font-family: 'xiaocheng';*/

  font-weight: 800;
  color: #FB884F;
  margin-left: 0.06rem;
}

.price div:nth-child(1) span:nth-child(4){
  font-size: 0.33rem;
  /*font-family: 'xiaocheng';*/

  font-weight: 400;
  color: #FB884F;
  margin-left: 0.02rem;
}
.price div:nth-child(2) {
  font-size: 0.33rem;
  /*font-family: 'xiaocheng';*/

  font-weight: 400;
  color: #999999;
}
.RentThings{
  margin-bottom: 0.5rem;
}
.RentThings img{
  width: 100%;
}
/*/deep/.van-swipe-item{*/
/*  border-radius: 0.28rem;*/
/*}*/

.popularityList{
  height: 5.2rem;
  background: #FFFFFF;
  border-radius: 0.21rem;
  padding:0.31rem 0rem 0.57rem 0.32rem;
}

.popularityList .popularityListTitle {
  display: flex;
  align-items: center;
  margin-bottom: 0.41rem;
}

.popularityList .popularityListTitle div:nth-child(1){
  font-size: 0.45rem;
  /*font-family: 'xiaocheng';*/

  font-weight: 550;
  color: #333333;
  margin-right: 0.35rem;
}

.popularityList .popularityListTitle div:nth-child(2){
  font-size: 0.32rem;
  /*font-family: 'xiaocheng';*/
  font-weight: 300;
  color: #999999;
  border-left: 0.03rem solid #999999;
  padding-left: 0.2rem;
}
.popularityList .popularityListContent{
  display: flex;
  overflow-y: scroll;
}

.popularityList .popularityListContent::-webkit-scrollbar{
  display: none;
}

.popularityList .popularityListContent .popularityListContentList{
  margin-right: 0.32rem;
}
.popularityList .popularityListContent .popularityListContentList .popularityListContentImg{
  position: relative;
}
.popularityList .popularityListContent .popularityListContentList .popularityListContentImg img:nth-child(1){
  width: 2.4rem;
}
.popularityList .popularityListContent .popularityListContentList .popularityListContentImg .ranking{
 position: absolute;
  top: 0rem;
  width: 0.51rem;
  left: 0rem;
}
.popularityList .popularityListContent .popularityListContentList{
  width: 2.4rem;
  text-align: center;
}
.popularityList .popularityListContent .popularityListContentList h2{
  font-size: 0.37rem;
  /*font-family: 'xiaocheng';*/
  font-weight: 400;
  color: #333333;
  margin-top: 0.15rem;
}
.popularityList .popularityListContent .popularityListContentList p{
  font-size: 0.48rem;
  /*font-family: 'xiaocheng';*/
  font-weight: bold;
  color: #FB884F;
  margin-top: 0.19rem;

}
.popularityList .popularityListContent .popularityListContentList p span{
  font-size: 0.32rem;
}
.cooperativeBrand{
  height: 4.13rem;
  background: #FFFFFF;
  border-radius: 0.21rem;
  margin: 0.32rem 0rem;
  padding: 0.31rem 0.31rem 0rem 0.31rem;
}
.cooperativeBrand h2{
  font-size: 0.45rem;
  font-weight: 550;
  color: #333333;
  /*font-family: 'xiaocheng';*/
  margin-bottom: 0.32rem;
}
.cooperativeBrand .cooperativeBrandImg{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cooperativeBrand .cooperativeBrandImg img{
  width: 2.69rem;
  margin-bottom: 0.32rem;
  background: #F7F7F7;
  border: 0.01rem solid #EAEAEA;
}
</style>
